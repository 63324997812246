import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { taskActions } from "_actions";
import { workItemActions } from "_actions";
import { appConfigActions } from "_actions";
import { projectActions } from "_actions";
import { store } from "_helpers";
import { validationUtils } from "_helpers";
import { commonUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import workItemUtils from "_helpers/workitem.utils";
import classNames from "classnames/bind";
import { notificationActions } from "_actions";

class TimesheetPageInputItem extends Component {
  constructor() {
    super();
    this.state = {
      hasFormError: false,
      editable: true,
      updateButtonEnabled: false,
      selectedProjects: []
    }
  }

  componentDidMount() {
    //console.log(" this.props.timeSheetRequest")
    //console.log(this.props.timeSheetRequest)
    this.setState({
      //timeSheetRequest: this.props.timeSheetRequest ? this.props.timeSheetRequest :
      // {}
    })
  }

  onAddProjectTaskTimeEntry = (selectedProjectOption, tasksTobeAdded) => { // type :BILLABLE/NONBILLABLE
    this.setState((prevState) =>
    ({
      selectedProjects: {
        ...prevState.selectedProjects,
        [selectedProjectOption.id]: {
          ...selectedProjectOption
        }
      }
    }));

    let tasks = (this.props.timeSheetRequest.project && this.props.timeSheetRequest.project[selectedProjectOption.id] && this.props.timeSheetRequest.project[selectedProjectOption.id].tasks) || []
    // if(tasksTobeAdded && Object.keys(tasksTobeAdded).length > 0) {
    tasks.push(tasksTobeAdded);
    //}
    /*this.setState((prevState) =>
    ({
      timeSheetRequest: {
        ...this.props.timeSheetRequest,
        "project": {
          ...this.props.timeSheetRequest.project,
          [selectedProjectOption.id]: {
            ...this.props.timeSheetRequest.project[selectedProjectOption.id],
            "isNew": (this.props.timeSheetRequest.project[selectedProjectOption.id] && this.props.timeSheetRequest.project[selectedProjectOption.id].isNew) || true,
            "projectName": selectedProjectOption.name,
            "projectCode": selectedProjectOption.code,
            "tasks": tasks
          }
        }
      }
    }), () => {
      //console.log(this.props.timeSheetRequest);
      this.props.onChange(this.props.name, this.props.timeSheetRequest);
    });*/
    this.props.onChange({
      ...this.props.timeSheetRequest,
      "project": {
        ...this.props.timeSheetRequest.project,
        [selectedProjectOption.id]: {
          ...this.props.timeSheetRequest.project[selectedProjectOption.id],
          "isNew": (this.props.timeSheetRequest.project[selectedProjectOption.id] && this.props.timeSheetRequest.project[selectedProjectOption.id].isNew) || true,
          "projectName": selectedProjectOption.name,
          "projectCode": selectedProjectOption.code,
          "tasks": tasks
        }
      }
    });
  }
  onAddNonBillabelCategoryTimeEntry = (categoryName, tasksTobeAdded) => { // type :BILLABLE/NONBILLABLE

    let tasks = (this.props.timeSheetRequest.nonProject && this.props.timeSheetRequest.nonProject[categoryName] && this.props.timeSheetRequest.nonProject[categoryName].tasks) || []
    // if(tasksTobeAdded && Object.keys(tasksTobeAdded).length > 0) {
    tasks.push(tasksTobeAdded);
    //}
    /*this.setState((prevState) =>
    ({
      timeSheetRequest: {
        ...this.props.timeSheetRequest,
        "nonProject": {
          ...this.props.timeSheetRequest.nonProject,
          [categoryName]: {
            ...this.props.timeSheetRequest.nonProject[categoryName],
            "isNew": (this.props.timeSheetRequest.nonProject[categoryName] && this.props.timeSheetRequest.nonProject[categoryName].isNew) || true,
            "tasks": tasks
          }
        }
      }
    }), () => {
      //console.log(this.props.timeSheetRequest);
      this.props.onChange(this.props.name, this.props.timeSheetRequest);
    });*/
    this.props.onChange({
      ...this.props.timeSheetRequest,
      "nonProject": {
        ...this.props.timeSheetRequest.nonProject,
        [categoryName]: {
          ...this.props.timeSheetRequest.nonProject[categoryName],
          "isNew": (this.props.timeSheetRequest.nonProject[categoryName] && this.props.timeSheetRequest.nonProject[categoryName].isNew) || true,
          "tasks": tasks
        }
      }
    });
  }

  onChange = (type, taskGroupItems) => {
    this.setState((prevState) =>
    ({
      /* timeSheetRequest: {
         ...this.props.timeSheetRequest,
         [type]: taskGroupItems
       },*/
      updateButtonEnabled: true,
      hasFormError: false
    }), () => {
      //  //console.log(this.props.timeSheetRequest);
      // this.props.onChange(this.props.name, this.props.timeSheetRequest);
    });
    this.props.onChange({
      ...this.props.timeSheetRequest,
      [type]: taskGroupItems
    });
  }

  onResetFormError = () => {
    this.setState({
      hasFormError: false
    })
  }

  handleUpdateTimeSheet = () => {
    let isValid = false;
    let index = 0;
    this.props.timeSheetRequest && Object.keys(this.props.timeSheetRequest).forEach(timeSheet => {
      Object.keys(this.props.timeSheetRequest[timeSheet]).forEach(timeSheetItem => {
        this.props.timeSheetRequest[timeSheet][timeSheetItem].tasks && this.props.timeSheetRequest[timeSheet][timeSheetItem].tasks.forEach(task => {
          if (task.isNew === true) {
            let isTaskValid = ((task.isNew === true && task.isValid === true));
            if (index > 0) {
              isValid = isValid && isTaskValid;
            } else {
              isValid = isTaskValid;
            }
            index++;
          }
        })
      })
    })
    if (isValid) {
      this.props.handleUpdateTimeSheet(() => { this.toggleEditOption(false) });
    } else {
      this.setState({
        hasFormError: true
      })
    }
    //this.toggleEditOption(false);
  }

  onFormError = (fieldName, hasError) => {
    this.setState(prevState => ({
      formErrors: {
        ...prevState.formErrors,
        [fieldName]: hasError
      }
    }))
  }

  toggleEditOption = (isEdit) => {
    this.setState({
      updateButtonEnabled: isEdit
    })
  }

  resetTimeSheet = () => {
    this.toggleEditOption(false)
    this.props.resetTimeSheet();
  }

  handleAddProjectForTimeEntry = (typeSelectionOption, categorySelectionOption, projectSelectionOption) => {
    if (typeSelectionOption && typeSelectionOption.value === "project") {
      this.onAddProjectTaskTimeEntry(projectSelectionOption,
        {
          "isNew": true,
          "isValid": false,
          "employeeId": this.props.selectedEmployee.id,
          "userTeamId": this.props.selectedEmployee.userTeamId,
        });
    } else {
      //console.log(categorySelectionOption)
      this.onAddNonBillabelCategoryTimeEntry(categorySelectionOption.value,
        {
          "isNew": true,
          "isValid": false,
          "taskActCategory": categorySelectionOption.value,
          "workItemName": categorySelectionOption.value,
          "employeeId": this.props.selectedEmployee.id,
          "userTeamId": this.props.selectedEmployee.userTeamId,
        })
    }
  }

  render() {
    let totalByDateMap = this.props.applicableDates.currentWeekDates.map(dateString => { return { [dateString]: { "totalMins": 0, "billableMins": 0, "nonProjectMins": 0, "leaveOrHolidayMins": 0 } } })
    totalByDateMap = totalByDateMap.reduce((a, v) => ({ ...a, ...v }));
    this.props.timeSheetRequest && Object.keys(this.props.timeSheetRequest).map(timesheetType => {
      let taskGroupItems = this.props.timeSheetRequest[timesheetType];
      return taskGroupItems && Object.keys(taskGroupItems).map(taskGroupId => {
        let timeEntryTaskSection = taskGroupItems[taskGroupId];
        let timeSheetTaskList = timeEntryTaskSection && timeEntryTaskSection.tasks;
        return timeSheetTaskList && timeSheetTaskList.map((timeSheetTaskItem, index) => {
          let timeEntry = timeSheetTaskItem && timeSheetTaskItem.timeEntry;
          return timeEntry && Object.keys(timeEntry).map(dateString => {

            if (this.props.applicableDates.currentWeekDates.includes(dateString)) {
              let time = parseInt((timeEntry[dateString] && timeEntry[dateString].timesheetTimeMins) || 0);
              totalByDateMap[dateString].totalMins += time;
              if (timesheetType === 'project') {
                totalByDateMap[dateString].billableMins += time;
              } else if (timesheetType === 'nonProject') {
                if (timeSheetTaskItem.taskActCategory && (timeSheetTaskItem.taskActCategory.toLowerCase() === 'leave' || timeSheetTaskItem.taskActCategory.toLowerCase() === 'holiday')) {
                  totalByDateMap[dateString].leaveOrHolidayMins += time;
                } else {
                  totalByDateMap[dateString].nonProjectMins += time;
                }
              }
            }

          })
        })
      })
    })
    return (
      <div className="table-responsive timesheetpage">
        <div>
          {this.state.editable &&
            <AddNewProjectModel
              showAddProjectOption={this.props.showSelectionWindow}
              cancelShowSelectionWindow={this.props.cancelShowSelectionWindow}
              handleAddProjectForTimeEntry={this.handleAddProjectForTimeEntry}
              initializeNonProjectWorkItem={this.props.initializeNonProjectWorkItem}
              selectedEmployee={this.props.selectedEmployee}
              startOfTheWeek={this.props.startOfTheWeek}
              projectsForTimesheet={this.props.projectsForTimesheet}
              nonProjectAppConfig={this.props.nonProjectAppConfig}
              nonProjectFieldConfig={this.props.nonProjectFieldConfig}
              projectFieldConfig={this.props.projectFieldConfig}
              allUserTeams={this.props.allUserTeams}
              getProjectList={this.props.getProjectList}
              permission={this.props.permission}
            />
          }
        </div>
        <table className="table table-borderless">
          <thead>
            <tr className="timesheet-header border-bottom sticky-top-25">
              <th colSpan="5"></th>
              {this.props.applicableDates && this.props.applicableDates.currentWeekDates.map((dateString, index) =>
                <th className="dayinput" key={index}><span>{dateUtils.WEEK_NAMES[index]}</span><span className="dateinput text-muted fsize9">{dateString}</span></th>
              )}
              <th className="w-40"></th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.timeSheetRequest && Object.keys(this.props.timeSheetRequest).map(timesheetType =>
                <TimesheetPageTaskGroupInputItem key={timesheetType}
                  permission={this.props.permission}
                  selectedEmployee={this.props.selectedEmployee}
                  editable={this.state.editable}
                  onChange={this.onChange}
                  taskGroupItems={this.props.timeSheetRequest[timesheetType]} //billable or nonbillable
                  timesheetType={timesheetType}
                  projectsForTimesheet={this.props.projectsForTimesheet}
                  selectedProjects={this.state.selectedProjects}
                  applicableDates={this.props.applicableDates}
                  nonProjectAppConfig={this.props.nonProjectAppConfig}
                  nonProjectFieldConfig={this.props.nonProjectFieldConfig}
                  projectFieldConfig={this.props.projectFieldConfig}
                  allUserTeams={this.props.allUserTeams}
                  allProjectTeamAccounts={this.props.allProjectTeamAccounts || this.props.allProjectAccounts}
                />
              )
            }
          </tbody>
          <tfoot>
            <tr className="border-bottom">
              <td colSpan="4"></td>
              <td className="fsize15 text-right fweight600">Total</td>
              {totalByDateMap && Object.keys(totalByDateMap).map((dateString, index) =>
                <td className={`text-center bgcolorlightblue_1 fsize14 fweight600`} key={index}>
                  <span className={`${totalByDateMap[dateString].totalMins > parseInt(this.props.permission.maxHoursAllowedToEnter || 0) * 60 ? 'colorred blink_text' : ''}`}>{dateUtils.minuteToHourString(totalByDateMap[dateString].totalMins)}</span></td>
              )}
              <td></td>
            </tr>
            <tr>
              <td colSpan="13">
                <hr />
                {this.state.editable && this.state.updateButtonEnabled &&

                  <div className=" col-12 pt-2">
                    {this.state.hasFormError &&
                      <div className="alert alert-danger alert-dismissible mb-0 my-2 py-1">
                        <button type="button" className="close py-1" onClick={() => this.onResetFormError()}></button>
                        <strong><i className="fa fa-exclamation-triangle colorred"></i></strong> Error : Please complete the mandatory fields before Submitting!
                      </div>
                    }
                    <button onClick={() => this.handleUpdateTimeSheet()} className="btn btn-primary btn-sm mr-1">
                      Update Timesheet
                    </button>
                    <button onClick={() => this.resetTimeSheet()} className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue">
                      Cancel
                    </button>
                  </div>
                }              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

class TimesheetPageTaskGroupInputItem extends Component {

  constructor() {
    super();
    this.state = {
    }
  }

  removeProjectFromTimesheet = (itemTobeRemoved) => {
    //console.log("removeProjectFromTimesheet " + itemTobeRemoved)
    let taskGroupItemsUpdated = { ...this.props.taskGroupItems };
    delete taskGroupItemsUpdated[itemTobeRemoved];
    this.props.onChange(this.props.timesheetType, taskGroupItemsUpdated);
  }

  addTaskToTimeSheetTimesheet = (groupId) => {
    let taskList = this.props.taskGroupItems[groupId].tasks;
    let newTask = null;
    if (this.props.timesheetType === "project") {
      newTask = {
        "isNew": true,
        "isValid": false,
        "employeeId": this.props.selectedEmployee.id,
        "userTeamId": this.props.selectedEmployee.userTeamId,
      }
    } else {
      newTask = {
        "isNew": true,
        "isValid": false,
        "taskActCategory": groupId,
        "workItemName": groupId,
        "employeeId": this.props.selectedEmployee.id,
        "userTeamId": this.props.selectedEmployee.userTeamId,
      }
    }
    taskList.push(newTask);
    this.onChange(groupId, taskList);

  }

  onChange = (groupId, timeSheetTaskList, isValidObject) => {

    const taskGroupItemsUpdated = {
      ...this.props.taskGroupItems,
      [groupId]: {
        ...this.props.taskGroupItems[groupId],
        "tasks": timeSheetTaskList
      }
    }
    this.props.onChange(this.props.timesheetType, taskGroupItemsUpdated, isValidObject);

  }

  render() {
    return (
      this.props.taskGroupItems && Object.keys(this.props.taskGroupItems).map(taskGroupId => {
        let timeEntryTaskSection = this.props.taskGroupItems[taskGroupId];
        return <React.Fragment key={taskGroupId}>
          <tr className="timesheet-inputs border-top" key={taskGroupId + "-MH"}>
            <td colSpan="13"><span className="fsize12 fweight600">
              {this.props.timesheetType === "project" &&
                <>
                  [<i> {timeEntryTaskSection.projectCode || 'BILLABLE'} </i>]
                  <span className="pl-2 fsize14"> {timeEntryTaskSection.projectName}</span>
                </>
              }
              {this.props.timesheetType === 'nonProject' &&
                <>
                  [<i> NON BILLABLE </i>]
                  <span className="pl-2 fsize14">{taskGroupId}</span>
                </>
              }
            </span></td>
          </tr>
          <tr className="timesheet-inputs border-bottom" key={taskGroupId + "-PH"}>
            {this.props.timesheetType === "project" &&
              <>

                <td className="py-0 text-muted fsize13 fweight600">Deliverable</td>
                <td className="py-0 text-muted"></td>
                <td className="py-0 text-muted fsize13 fweight600">Category</td>
                <td className="py-0 text-muted"></td>
                <td className="py-0 text-muted fsize13 fweight600">Work Item</td>
              </>
            }
            {this.props.timesheetType === 'nonProject' &&
              <>

                <td className="py-0 text-muted fsize13 fweight600">Division</td>
                <td className="py-0 text-muted fsize13 fweight600">Account</td>
                <td className="py-0 text-muted fsize13 fweight600">Client Partner</td>
                <td className="py-0 text-muted fsize13 fweight600">Requesting Team</td>
                <td className="py-0 text-muted fsize13 fweight600">Work Item</td>
              </>
            }
            <td colSpan="7"></td>
            <td className="actions-section ">
              {timeEntryTaskSection.isNew &&
                <>
                  <button className="link pr-2 actions-item" onClick={() => this.addTaskToTimeSheetTimesheet(taskGroupId)}><i className="fa fa-plus colorblue"></i></button>
                  <button className="link actions-item" onClick={() => this.removeProjectFromTimesheet(taskGroupId)}><i className="fa fa-close colorred"></i></button>
                </>
              }
            </td>
          </tr>
          <TimeSheetTaskListInput
            permission={this.props.permission}
            timesheetType={this.props.timesheetType}
            selectedEmployee={this.props.selectedEmployee}
            key={taskGroupId + "-TH"}
            timeSheetTaskList={timeEntryTaskSection.tasks}
            selectedProject={this.props.selectedProjects && this.props.selectedProjects[taskGroupId]}
            taskGroupId={taskGroupId}
            applicableDates={this.props.applicableDates}
            nonProjectAppConfig={this.props.nonProjectAppConfig}
            nonProjectFieldConfig={this.props.nonProjectFieldConfig}
            projectFieldConfig={this.props.projectFieldConfig}
            allUserTeams={this.props.allUserTeams}
            allProjectTeamAccounts={this.props.allProjectTeamAccounts}
            onChange={this.onChange}
          />
        </React.Fragment>
      }
      )
    );
  }
}

class TimeSheetTaskListInput extends Component {
  constructor() {
    super();
    this.state = {
      timeSheetTaskList: []
    }
  }
  componentDidMount() {
    this.setState({
      // timeSheetTaskList: this.props.timeSheetTaskList
    })
  }
  removeTaskFromTimesheet = (taskIndexTobeRemoved) => {
    //console.log("removeTaskFromTimesheet " + taskIndexTobeRemoved)
    let timeSheetTaskListUpdated = this.props.timeSheetTaskList;
    timeSheetTaskListUpdated.splice(taskIndexTobeRemoved, 1);

    //console.log(timeSheetTaskListUpdated);
    //console.log("this.props.taskGroupId " + this.props.taskGroupId)
    this.props.onChange(this.props.taskGroupId, timeSheetTaskListUpdated, { [taskIndexTobeRemoved]: true });

  }

  onChange = (updatedTimeSheetTaskItem, index) => {

    let timeSheetTaskListUpdated = this.props.timeSheetTaskList;
    timeSheetTaskListUpdated[index] = updatedTimeSheetTaskItem;
    this.props.onChange(this.props.taskGroupId, timeSheetTaskListUpdated);

  }

  render() {

    return (
      this.props.timeSheetTaskList && this.props.timeSheetTaskList.map((timeSheetTaskItem, index) =>
        <TimeSheetTaskItem key={index}
          timesheetType={this.props.timesheetType}
          selectedEmployee={this.props.selectedEmployee}
          permission={this.props.permission}
          timeSheetTaskItem={timeSheetTaskItem}
          selectedProject={this.props.selectedProject}
          nonProjectAppConfig={this.props.nonProjectAppConfig}
          nonProjectFieldConfig={this.props.nonProjectFieldConfig}
          projectFieldConfig={this.props.projectFieldConfig}
          allUserTeams={this.props.allUserTeams}
          applicableDates={this.props.applicableDates}
          taskIndex={index}
          taskGroupId={this.props.taskGroupId}
          removeTaskFromTimesheet={this.removeTaskFromTimesheet}
          allProjectTeamAccounts={this.props.allProjectTeamAccounts}
          onChange={this.onChange}
        />
      )
    )
  }
}

class TimeSheetTaskItem extends Component {
  constructor() {
    super();
    this.state = {
      timeSheetTaskItem: {},
      selectedDeliverableOption: null,
      formErrors: {},
      disableDeliveryOptionSelection: false
    }
  }

  onTimeEntryValueChange = (valueInHours, dateString) => {
    if (!validationUtils.validateDecimalNumberic(valueInHours) || (valueInHours && this.props.permission.maxHoursAllowedToEnter < parseFloat(valueInHours) || (parseFloat(valueInHours) - parseInt(valueInHours) > .59))) {
      return;
    }
    let valueInMins = 0;
    if (valueInHours !== "") {
      valueInMins = (parseInt(valueInHours) * 60) + (Math.round((parseFloat(valueInHours) - parseInt(valueInHours)) * 100));
    }
    let timeEntry = {}
    if (this.props.timeSheetTaskItem && this.props.timeSheetTaskItem.timeEntry) {
      timeEntry = this.props.timeSheetTaskItem.timeEntry;
      if (!this.props.timeSheetTaskItem.timeEntry[dateString]) {
        timeEntry = {
          ...timeEntry,
          [dateString]: {
            "isNew": true
          }
        }
      } else {
        timeEntry = {
          ...timeEntry,
          [dateString]: {
            ...timeEntry[dateString],
            "isUpdated": true
          }
        }
      }
    } else {
      timeEntry = {
        [dateString]: {
          "isNew": true
        }
      }
    }

    const timeSheetTaskItemUpdated = {
      ...this.props.timeSheetTaskItem,
      "timeEntry": {
        ...timeEntry,
        [dateString]: {
          ...timeEntry[dateString],
          "timesheetTimeMins": valueInMins,
          "timesheetTimeHours": valueInHours
        }
      }
    }

    this.setState({
      disableDeliveryOptionSelection: true
    })

    this.onChange(timeSheetTaskItemUpdated, this.props.taskIndex);

  }

  onAdditionalFieldValueChange = (name, value, dateString) => {

    const timeSheetTaskItemUpdated = {
      ...this.props.timeSheetTaskItem,
      "timeEntry": {
        ...this.props.timeSheetTaskItem.timeEntry,
        [dateString]: {
          ...this.props.timeSheetTaskItem.timeEntry[dateString],
          [name]: value
        }
      }
    }
    this.onChange(timeSheetTaskItemUpdated, this.props.taskIndex);
  }

  onFormError = (fieldName, hasError) => {
    this.setState(prevState => ({
      formErrors: {
        ...prevState.formErrors,
        [fieldName]: hasError
      }
    }))
  }

  getNonBillableFieldConfig = () => {
    return this.state.timeSheetTaskItem && workItemUtils.filterNonMandatoryFields(this.props.nonProjectFieldConfig, this.props.timeSheetTaskItem.taskActCategory, this.props.nonProjectAppConfig);
  }
  onChange = (timeSheetTaskItem, index) => {

    const fieldConfig = this.props.timesheetType === "project" ? this.props.projectFieldConfig : this.getNonBillableFieldConfig();
    let isValid = validationUtils.isValidRequest(fieldConfig, timeSheetTaskItem, this.state.formErrors);

    timeSheetTaskItem = {
      ...timeSheetTaskItem,
      "isValid": isValid
    }
    this.props.onChange(timeSheetTaskItem, index);
  }

  onFieldValueChange = (name, value) => {

    let workItemName = this.props.timeSheetTaskItem.workItemName;
    let taskActCategory = this.props.timeSheetTaskItem.taskActCategory;
    if (name === 'taskActCategory') {
      workItemName = value;
    }
    if (name === 'taskId') {
      taskActCategory = null;
    }
    let timeSheetTaskItemUpdated = {
      ...this.props.timeSheetTaskItem,
      "workItemName": workItemName,
      "taskActCategory": taskActCategory,
      [name]: value,
    }

    this.onChange(timeSheetTaskItemUpdated, this.props.taskIndex);
  }
  addAdditionalTaskDetails = (dateString, additionalTaskDetails) => {

    const timeSheetTaskItemUpdated = {
      ...this.props.timeSheetTaskItem,
      "timeEntry": {
        ...this.props.timeSheetTaskItem.timeEntry,
        [dateString]: {
          ...this.props.timeSheetTaskItem.timeEntry[dateString],
          ...additionalTaskDetails
        }
      }
    }
    this.onChange(timeSheetTaskItemUpdated, this.props.taskIndex);

  }

  convertMinsToHours = (timeInMins) => {
    if (timeInMins && timeInMins !== '') {
      return (Math.floor(timeInMins / 60) + ((timeInMins % 60) / 100)).toFixed(2);
    }
    return '';
  }
  isCreateDateEnabled = (dateString) => {
    let workItemStartDate = "";
    let workItemEndDate = "";
    if (this.props.timesheetType === "nonProject") {
      if (this.props.taskGroupId.toLowerCase() === 'leave' || this.props.taskGroupId.toLowerCase() === 'holiday') {
        if (this.props.permission.allowLeaveAndHolidayForPast) {
          workItemStartDate = this.props.applicableDates.workItemMinDates.actualStartDate;
        } else {
          workItemStartDate = this.props.applicableDates.workItemMinDates.nonProjectStart;
        }
        if (this.props.permission.allowLeaveAndHolidayForFuture) {
          workItemEndDate = this.props.applicableDates.workItemMaxDates.actualEndDate;
        } else {
          workItemEndDate = this.props.applicableDates.workItemMaxDates.nonProjectEnd;
        }
      } else {
        workItemStartDate = this.props.applicableDates.workItemMinDates.nonProjectStart;
        workItemEndDate = this.props.applicableDates.workItemMaxDates.nonProjectEnd;
      }
    } else if (this.props.timesheetType === "project") {
      workItemStartDate = this.props.applicableDates.workItemMinDates.projectStart;
      workItemEndDate = this.props.applicableDates.workItemMaxDates.projectEnd;

      if (this.state.selectedDeliverableOption && this.state.selectedDeliverableOption.estStartTime) {
        const deliverableEstStartTime = dateUtils.convertDateStringToMoment(this.state.selectedDeliverableOption.estStartTime).clone().startOf('day');
        if (workItemStartDate.diff(deliverableEstStartTime) < 0) {
          workItemStartDate = deliverableEstStartTime
        }
      }
      if (this.state.selectedDeliverableOption && this.state.selectedDeliverableOption.estCompletionTime) {
        const deliverableEstCompletionTime = dateUtils.convertDateStringToMoment(this.state.selectedDeliverableOption.estCompletionTime).clone().endOf('day');
        if (workItemEndDate.diff(deliverableEstCompletionTime) > 0) {
          workItemEndDate = deliverableEstCompletionTime
        }
      }
    }

    let momentDate = dateUtils.convertDateStringToMoment(dateString).clone().startOf("day");

    return momentDate.diff(workItemStartDate) >= 0 && momentDate.diff(workItemEndDate) <= 0;

  }

  onDeliveryFieldValueChange = (fieldName, selectedDeliverableOption) => {
    if (this.state.disableDeliveryOptionSelection === false) {
      this.setState(prev => ({
        selectedDeliverableOption: selectedDeliverableOption
      }), () => {
        this.props.onChange(this.props.timeSheetTaskItem, this.props.taskIndex);
      }
      );
      this.onFieldValueChange(fieldName, selectedDeliverableOption.id)
    } else {
      store.dispatch(notificationActions.warning("Once a timesheet entry is added, deliverables cannot be altered."));
    }
  }

  render() {
    let timeEntry = (this.props.timeSheetTaskItem && this.props.timeSheetTaskItem.timeEntry) || {};
    return (
      <tr className={`timesheet-inputs tasks ${this.props.timesheetType === "nonProject" && (this.props.timeSheetTaskItem.taskActCategory === 'HOLIDAY' ? 'colorred' : this.props.timeSheetTaskItem.taskActCategory === 'LEAVE' ? 'colororange' : '')}`}>
        {this.props.timesheetType === "project" && this.props.timeSheetTaskItem.taskName &&
          <>

            <td colSpan={2}>{this.props.timeSheetTaskItem.taskName}</td>
            <td colSpan={2}>{this.props.timeSheetTaskItem.taskActCategory}</td>
            <td className="fsize12 fweight600">{this.props.timeSheetTaskItem.workItemName}</td>
          </>
        }
        {this.props.timesheetType === "project" && !this.props.timeSheetTaskItem.taskName &&
          <ProjectTaskSelector
            selectedProject={this.props.selectedProject}
            selectedDeliverableOption={this.state.selectedDeliverableOption}
            onDeliveryFieldValueChange={this.onDeliveryFieldValueChange}
            permission={this.props.permission}
            selectedEmployee={this.props.selectedEmployee}
            projectAppConfig={this.state.projectAppConfig}
            projectFieldConfig={this.props.projectFieldConfig}
            timeSheetTaskItem={this.props.timeSheetTaskItem}
            onFieldValueChange={this.onFieldValueChange}
            onFormError={this.onFormError}
          />
        }
        {this.props.timesheetType === "nonProject" && ((this.props.timeSheetTaskItem.isNew === undefined || !this.props.timeSheetTaskItem.isNew) || (this.props.taskGroupId === 'Holiday' || this.props.taskGroupId === 'Leave')) &&
          <>


            <td>{this.props.timeSheetTaskItem.division || ''}</td>
            <td>{this.props.timeSheetTaskItem.account || ''}</td>
            <td>{this.props.timeSheetTaskItem.clientPartner || ''}</td>
            <td>{this.props.timeSheetTaskItem.requestingTeamName || ''}</td>
            <td className="fsize12 fweight600">{this.props.timeSheetTaskItem.workItemName || ''}</td>
          </>
        }
        {this.props.timesheetType === "nonProject" && ((this.props.timeSheetTaskItem.isNew !== undefined && this.props.timeSheetTaskItem.isNew) && (this.props.taskGroupId !== 'Holiday' && this.props.taskGroupId !== 'Leave')) &&
          <NonProjectTaskSelector
            nonProjectAppConfig={this.props.nonProjectAppConfig}
            getNonBillableFieldConfig={this.getNonBillableFieldConfig}
            allUserTeams={this.props.allUserTeams}
            timeSheetTaskItem={this.props.timeSheetTaskItem}
            allProjectTeamAccounts={this.props.allProjectTeamAccounts}
            onFieldValueChange={this.onFieldValueChange}
            onFormError={this.onFormError}
          />
        }
        {
          this.props.applicableDates.currentWeekDates.map(dateString => {
            let timeInMins = (timeEntry && timeEntry[dateString] && timeEntry[dateString].timesheetTimeMins) || '';
            let timeInHours = (timeEntry && timeEntry[dateString] && timeEntry[dateString].timesheetTimeHours) || null;

            return <td key={dateString} className="text-center border-left border-right">
              <span className="d-block fweight600 fsize15">
                {(this.state.selectedDeliverableOption || this.props.timesheetType === "nonProject") && this.isCreateDateEnabled(dateString) && ((timeEntry[dateString] && timeEntry[dateString].isEditable === true) || this.props.timeSheetTaskItem.isNew === true) &&
                  <>
                    <input type="text" onChange={(e) => this.onTimeEntryValueChange(e.target.value, dateString)} className="form-control px-1 py-0 state-valid" value={timeInHours || this.convertMinsToHours(timeInMins)} />
                    {timeEntry[dateString] && timeEntry[dateString].timesheetTimeMins > 0 &&
                      <TimeEntryAdditionalFields
                        selectedEmployeeOption={this.props.selectedEmployee}
                        selectedDeliverableOption={this.state.selectedDeliverableOption}
                        permission={this.props.permission}
                        timesheetType={this.props.timesheetType}
                        onFieldValueChange={this.onAdditionalFieldValueChange}
                        dateString={dateString}
                        timeEntryValues={timeEntry[dateString]}
                        projectFieldConfig={this.props.projectFieldConfig}
                        onFormError={this.onFormError}
                      />
                    }
                  </>
                }
                {timeEntry[dateString] && !timeEntry[dateString].isEditable && timeEntry[dateString].isNew === false && timeEntry[dateString].timesheetTimeMins > 0 &&
                  <>
                    {timeInHours || this.convertMinsToHours(timeInMins)}
                  </>
                }
              </span>
            </td>
          }
          )
        }
        <td className="actions-section">
          {this.props.timeSheetTaskItem.isNew &&
            <button className="link actions-item" onClick={() => this.props.removeTaskFromTimesheet(this.props.taskIndex)}><i className="fa fa-close colorred"></i></button>
          }
        </td>
      </tr >
    )
  }
}

class TimeEntryAdditionalFields extends Component {
  constructor() {
    super();
    this.state = {
      showDropDown: false
    }
  }
  toggleShowDropDown = (showDropDown) => {
    this.setState({
      showDropDown: showDropDown
    })
  }

  getRestrictedSkillOptions = () => {
    let employeeSkills = commonUtils.getEmployeeSkills(this.props.selectedEmployeeOption);
    const restrictedByEmployeeSkills = this.props.selectedDeliverableOption && this.props.selectedDeliverableOption.restrictedByEmployeeSkills;

    if (this.props.permission.restrictedBySkillsEnabled === true && restrictedByEmployeeSkills && employeeSkills) {
      employeeSkills = employeeSkills.filter(skill => restrictedByEmployeeSkills.includes(skill));
    }
    return commonUtils.convertConfigListToSelectObject(employeeSkills);
  }

  onChange = (fieldName, value) => {
    this.props.onFieldValueChange(fieldName, value, this.props.dateString)
  }
  render() {
    return (
      <span className="text-left">
        <button className="link" onClick={() => this.toggleShowDropDown(!this.state.showDropDown)}>
          <i className="fe fe-file-text p-1 fsize8 mt-1"></i>
        </button>
        <div className={classNames("dropdowncontainer", { "d-none": !this.state.showDropDown })} >

          <div className="dropdownmenu-timesheet custom-controls-stacked font-weight-normal text-capitalize w-200">
            <div className="fsize14 text-muted border-bottom pb-1 pl-2">
              <span className="">Additional Details</span>
            </div>
            <div className="pl-2 ">
              <div className="pr-3">

                <CustomInput
                  type='textarea'
                  label="Comments"
                  name="comments"
                  height={"35px"}
                  fieldConfig={this.props.projectFieldConfig}
                  mappingObject={this.props.timeEntryValues}
                  onChange={this.onChange}
                  placeholder="Comments..."
                  onFormError={this.props.onFormError}
                  datatype='alpnumspl'
                />
                {this.props.timesheetType === 'project' &&
                  <>
                    <CustomInput
                      formClass={"w-100p"}
                      type='select'
                      label="Skill"
                      name="skill"
                      fieldConfig={this.props.projectFieldConfig}
                      mappingObject={this.props.timeEntryValues}
                      onChange={this.onChange}
                      placeholder="Skill..."
                      options={this.props.selectedEmployeeOption && this.getRestrictedSkillOptions()}
                      onFormError={this.props.onFormError}
                    />
                    <CustomInput
                      formClass={"w-100p"}
                      type='text'
                      datatype='num'
                      label="Quantity"
                      name="quantity"
                      fieldConfig={this.props.projectFieldConfig}
                      mappingObject={this.props.timeEntryValues}
                      onChange={this.onChange}
                      placeholder="Quantity.."
                      onFormError={this.props.onFormError}
                    />
                  </>
                }
              </div>
            </div>
            <div className="pr-3 pt-1 mt-2 border text-right">
              <button className={classNames("plainbutton fsize12 py-0 px-2 mb-1 ml-1 text-upper border fweight600 fsize13")}
                onClick={() => this.toggleShowDropDown(!this.state.showDropDown)}>
                Done
              </button>
            </div>
          </div>
        </div>
      </span>
    )
  }
}

class ProjectTaskSelector extends Component {
  constructor() {
    super();
    this.state = {

    }
  }
  processProjectModuleData = (moduleConfig) => {
    this.setState({
      projectAppConfig: moduleConfig
    })
  }

  componentDidMount() {
    if (this.props.selectedProject) {
      store.dispatch(projectActions.getModuleConfigData(this.props.selectedEmployee.userTeamId, this.processProjectModuleData))
    }
  }
  getRestrictedDeliverables = (selectedProjectOption) => {
    let deliverables = selectedProjectOption.taskInfoList;
    if (this.props.permission.restrictedBySkillsEnabled === true) {
      const employeeSkills = commonUtils.getEmployeeSkills(this.props.selectedEmployee);
      deliverables = selectedProjectOption.taskInfoList &&
        selectedProjectOption.taskInfoList.filter(deliverable => !deliverable.restrictedByEmployeeSkills || employeeSkills.some(skill => deliverable.restrictedByEmployeeSkills.includes(skill)));
      deliverables = deliverables && deliverables.filter(deliverable => !deliverable.restrictedByEmployeeIds || (this.state.selectedEmployeeOption && deliverable.restrictedByEmployeeIds.includes(this.props.selectedEmployee.value)));
    }
    if (commonUtils.getAppConfigValue(this.state.projectAppConfig, "enableRestrictProjectByEmployeeServiceLine") && deliverables) {
      deliverables = deliverables.filter(deliverable => this.props.selectedEmployee.serviceLines && this.props.selectedEmployee.serviceLines.includes(deliverable.serviceLine));
    }
    return commonUtils.convertObjectToSelectObject(deliverables)
  }

  getRestrictedDeliverableOptions = () => {
    return commonUtils.convertObjectToSelectObject(this.getRestrictedDeliverables(this.props.selectedProject))
  }

  render() {
    return (
      <>

        <td colSpan={2}>
          {this.props.selectedProject &&
            <div className="timesheet-select">

              <CustomInput
                readOnly={this.props.readOnly}
                type='select'
                name="taskId"
                fieldConfig={this.props.projectFieldConfig}
                mappingObject={{ ...this.props.timeSheetTaskItem, "taskId": this.props.selectedDeliverableOption }}
                onChange={this.props.onDeliveryFieldValueChange}
                placeholder="Deliverable..."
                options={(this.props.selectedProject && this.state.projectAppConfig && this.getRestrictedDeliverableOptions()) || []}
                onFormError={this.props.onFormError}
                getSelectOptionEnabled={true}
              />
            </div>
          }
        </td>
        <td colSpan={2}>
          {this.props.selectedProject &&
            <div className="timesheet-select">

              {(commonUtils.getAppConfigValue(this.state.projectAppConfig, "enableCategorySelection")) &&
                <CustomInput
                  readOnly={this.props.readOnly}
                  type='select'
                  name="taskActCategory"
                  fieldConfig={this.props.projectFieldConfig}
                  mappingObject={this.props.timeSheetTaskItem}
                  onChange={this.props.onFieldValueChange}
                  placeholder="Category..."
                  isAppConfig={true}
                  configName={"taskActCategory"}
                  createConfig={this.state.projectAppConfig}
                  onFormError={this.props.onFormError} />
              }

              {(!commonUtils.getAppConfigValue(this.state.projectAppConfig, "enableCategorySelection")) &&
                <CustomInput
                  type='select'
                  name="taskActCategory"
                  configName="taskActCategory"
                  fieldConfig={this.props.projectFieldConfig}
                  mappingObject={this.props.timeSheetTaskItem}
                  onChange={this.props.onFieldValueChange}
                  placeholder="Category..."
                  options={this.state.projectAppConfig && this.props.selectedDeliverableOption && workItemUtils.getWorkItemCategories(this.state.projectAppConfig, commonUtils.getAppConfigValue(this.state.projectAppConfig, "enableServiceLineEstimate") ? this.props.selectedDeliverableOption.serviceLine : this.state.selectedProject.category, this.props.selectedDeliverableOption.taskCategory)}
                  onFormError={this.props.onFormError}
                />
              }
            </div>
          }
        </td>
        <td className="w-200">
          <CustomInput
            type='text'
            name="workItemName"
            inputClassName={"workItemText"}
            fieldConfig={this.props.projectFieldConfig}
            mappingObject={this.props.timeSheetTaskItem}
            onChange={this.props.onFieldValueChange}
            placeholder="Work Item Name.."
            onFormError={this.props.onFormError}
          />
        </td>
      </>
    )
  }
}

class NonProjectTaskSelector extends Component {
  constructor() {
    super();
    this.state = {
      selectedRequestingTeamOption: null
    }
  }
  onSelectRequestingTeamOption = (fieldName, selectedRequestingTeamOption) => {
    this.setState({
      selectedRequestingTeamOption: selectedRequestingTeamOption
    })
    this.props.onFieldValueChange(fieldName, selectedRequestingTeamOption.label)
  }

  render() {
    let nonProjectFieldConfig = this.props.getNonBillableFieldConfig();
    return (
      <>


        <td>
          <div className="timesheet-select">

            <CustomInput
              readOnly={this.props.readOnly}
              type='select'
              name="division"
              fieldConfig={nonProjectFieldConfig}
              mappingObject={this.props.timeSheetTaskItem}
              onChange={this.props.onFieldValueChange}
              placeholder="Division..."
              isAppConfig={true}
              configName={"division"}
              createConfig={this.props.nonProjectAppConfig}
              onFormError={this.props.onFormError} />
          </div>
        </td>
        <td className="w-100">
          <div className="timesheet-select">
            <CustomInput
              readOnly={this.props.readOnly}
              type='select'
              name="account"
              fieldConfig={nonProjectFieldConfig}
              mappingObject={this.props.timeSheetTaskItem}
              onChange={this.props.onFieldValueChange}
              placeholder="Account..."
              options={this.props.allProjectTeamAccounts && commonUtils.getAccountOptions(this.props.allProjectTeamAccounts)}
              onFormError={this.props.onFormError} />
          </div>
        </td>
        <td>
          <div className="timesheet-select">
            <CustomInput
              readOnly={this.props.readOnly}
              type='select'
              name="clientPartner"
              fieldConfig={nonProjectFieldConfig}
              mappingObject={this.props.timeSheetTaskItem}
              onChange={this.props.onFieldValueChange}
              placeholder="Client..."
              isAppConfig={true}
              configName={"clientPartner"}
              createConfig={this.props.nonProjectAppConfig}
              onFormError={this.props.onFormError} />
          </div>
        </td>
        <td>
          <div className="timesheet-select">
            <CustomInput
              readOnly={this.props.readOnly}
              type='select'
              name="requestingTeamName"
              fieldConfig={nonProjectFieldConfig}
              mappingObject={{ ...this.props.timeSheetTaskItem, "requestingTeamName": this.state.selectedRequestingTeamOption }}
              onChange={this.onSelectRequestingTeamOption}
              placeholder="Team Name..."
              options={this.props.allUserTeams && commonUtils.convertObjectToSelectGroupObject(this.props.allUserTeams)}
              onFormError={this.props.onFormError}
              getSelectOptionEnabled={true}
            />
          </div>
        </td>
        <td>
          <CustomInput
            type='text'
            name="workItemName"
            inputClassName={"workItemText"}
            fieldConfig={nonProjectFieldConfig}
            mappingObject={this.props.timeSheetTaskItem}
            onChange={this.props.onFieldValueChange}
            placeholder="Work Item Name.."
            onFormError={this.props.onFormError} />

        </td>
      </>
    )
  }
}

class AddNewProjectModel extends Component {
  constructor() {
    super();
    this.state = {
      typeSelectionOption: null,
      categorySelectionOption: null,
      projectSelectionOption: null,
      nonBillableCategoryLoaded: false,
      reloadProjectAlways: true
    }
  }
  componentDidMount() {
    this.setState({
      startOfTheWeek: this.props.startOfTheWeek
    })
  }

  reloadProjectListService = () => {
    if ((this.state.startOfTheWeek !== this.props.startOfTheWeek || !this.props.projectsForTimesheet) || this.state.reloadProjectAlways) {
      let selectedCurrentUserId = this.props.selectedEmployee.id;
      let selectedUserTeamId = this.props.selectedEmployee.userTeamId;
      const dateMoment = dateUtils.toDateToMoment(this.props.startOfTheWeek);
      const startDate = dateMoment.format(dateUtils.SERVER_DATE_FORMAT);
      const endDate = dateUtils.addXDays(dateMoment, 7).format(dateUtils.SERVER_DATE_FORMAT);
      const isOnlyUserTeams = this.props.permission.listOnlyCurrentTeamProject;
      store.dispatch(this.props.getProjectList({ userTeamIds: [selectedUserTeamId], currentUserId: selectedCurrentUserId, startDate: startDate, endDate: endDate, isOnlyUserTeams }))
      this.setState({
        startOfTheWeek: this.props.startOfTheWeek,
        //reloadProject: false
      })

    }
  }

  onTypeSelectionOption = (typeSelectionOption) => {
    this.setState({
      typeSelectionOption: typeSelectionOption,
      categorySelectionOption: null,
      projectSelectionOption: null,
    })
    if (typeSelectionOption.value === "project") {
      this.reloadProjectListService();
    } else if (this.state.nonBillableCategoryLoaded === false) {
      store.dispatch(this.props.initializeNonProjectWorkItem(this.props.permission.timeSheetNonBillableShowAllAccountsEnabled === true ? true : false, this.props.selectedEmployee.userTeamId));
      store.dispatch(appConfigActions.getAllTeams(true));
      this.setState({
        nonBillableCategoryLoaded: true
      })
    }
  }
  onChangeCategorySelection = (categorySelectionOption) => {
    this.setState({
      categorySelectionOption: categorySelectionOption
    })

  }
  onChangeProjectSelection = (projectSelectionOption) => {
    this.setState({
      projectSelectionOption: projectSelectionOption
    })

  }

  handleAddProjectForTimeEntry = () => {
    this.props.handleAddProjectForTimeEntry(this.state.typeSelectionOption, this.state.categorySelectionOption, this.state.projectSelectionOption);
    this.setState({
      projectSelectionOption: null,
      categorySelectionOption: null
    })
  }

  render() {
    return (
      <div className="col-12">

        {this.props.showAddProjectOption &&
          <div className="row mx-3 my-1 addEstimateForm">

            <div className=" col-3">
              <div className="form-group">
                <label className="form-label">Select Type</label>

                <Select
                  isSearchable={true}
                  isClearable={false}
                  placeholder={"Select Type"}
                  classNamePrefix={'custom-select'}
                  isMulti={false}
                  menuPlacement={'auto'}
                  value={this.state.typeSelectionOption}
                  onChange={(selectedOption) => this.onTypeSelectionOption(selectedOption)}
                  options={[{ value: 'project', label: 'Billable' }, { value: 'nonProject', label: 'Non Billable' }]}
                />
              </div>
            </div>
            {this.state.typeSelectionOption && this.state.typeSelectionOption.value === "project" && this.props.projectsForTimesheet &&
              <div className=" col-3">
                <div className="form-group">
                  <label className="form-label">Select Project</label>
                  <Select
                    isSearchable={true}
                    isClearable={false}
                    placeholder={"Select Project"}
                    classNamePrefix={'custom-select'}
                    isMulti={false}
                    menuPlacement={'auto'}
                    value={this.state.projectSelectionOption}
                    onChange={(selectedOption) => this.onChangeProjectSelection(selectedOption)}
                    options={this.props.projectsForTimesheet && commonUtils.convertObjectToSelectObject(this.props.projectsForTimesheet)}
                  />
                </div>
              </div>
            }
            {this.state.typeSelectionOption && this.state.typeSelectionOption.value === 'nonProject' && this.props.nonProjectAppConfig &&
              <div className=" col-3">
                <div className="form-group">
                  <label className="form-label">Select Category</label>
                  <Select
                    isSearchable={true}
                    isClearable={false}
                    placeholder={"Select Category"}
                    classNamePrefix={'custom-select'}
                    isMulti={false}
                    menuPlacement={'auto'}
                    value={this.state.categorySelectionOption}
                    onChange={(selectedOption) => this.onChangeCategorySelection(selectedOption)}
                    options={this.props.nonProjectAppConfig && [{ value: 'HOLIDAY', label: 'Holiday' }, { value: 'LEAVE', label: 'Leave' }, ...commonUtils.convertConfigListToSelectObject(this.props.nonProjectAppConfig.find(config => config.name === "taskActCategory").value)]}
                  />
                </div>

              </div>
            }
            <div className=" col-12">
              {(this.state.categorySelectionOption || this.state.projectSelectionOption) &&
                <button onClick={() => this.handleAddProjectForTimeEntry()} className="plainbutton fsize12 py-0 px-2 mb-1 ml-1 text-upper border fweight600 fsize13">
                  Add
                </button>
              }
              <button onClick={() => this.props.cancelShowSelectionWindow(false)} className="create-link link pt-0  pl-3 pr-3 fsize12 colorblue">
                Cancel
              </button>
            </div>
          </div>
        }
      </div>
    )
  }
}


const mapStateToProps = state => ({
  projectsForTimesheet: state.projects.projectsForTimesheet,
  nonProjectAppConfig: state.workItem.nonProjectAppConfig,
  allUserTeams: state.appconfig.allUserTeamsByGroup,
  allProjectTeamAccounts: state.accounts.allProjectTeamAccounts,
  allProjectAccounts: state.accounts.allProjectAccounts,
  nonProjectFieldConfig: state.workItem.timeSheetNonProjectFieldConfig,
  projectFieldConfig: state.workItem.timeSheetProjectFieldConfig,
});

const mapDispatchToProps = dispatch => ({
  getProjectList: projectActions.getProjectListForTimesheet,
  addTimesheetData: taskActions.addTimesheetData,
  initializeNonProjectWorkItem: workItemActions.initializeNonProjectWorkItem,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesheetPageInputItem);



